import { forwardRef, cloneReactElement, combineRefs } from '@zap/utils/lib/ReactHelpers';
import * as React from 'react';
import { useEffect } from "react";
import { DomRegistrar, useDomScope, IDomRegistrar } from "./DomScope";

export interface IClickOutsideProps {
    onClick(event: MouseEvent): void;
}

export const ClickOutside = forwardRef(function ClickOutside(props: IClickOutsideProps & { children: React.ReactElement }, ref: React.Ref<any>) {
    let domRegistrar = useClickOutside(props.onClick);
    let childRef = domRegistrar.useChildRef();
    return <DomRegistrar.Provider value={domRegistrar}>
        {cloneReactElement(props.children, { ref: combineRefs(ref, childRef) })}
    </DomRegistrar.Provider>;
});

export function useClickOutside(onClickOutside: (e: MouseEvent) => void): IDomRegistrar {
    let domScope = useDomScope();

    useEffect(() => {
        document.addEventListener('mousedown', onDocumentClick);
        return () => document.removeEventListener('mousedown', onDocumentClick);
    });

    return domScope.registrar;

    function onDocumentClick(e: MouseEvent) {
        if (domScope.elements.every(el => !el.contains(e.target as HTMLElement)) && !eventIsScrollbarClick(e))
            onClickOutside(e);
    }

    function eventIsScrollbarClick(event: MouseEvent) {
        // client height/width do not include scroll bars, so click event with offsets outside this must be inside scroll bar
        return event.offsetY > (event.target as HTMLElement).clientHeight
            || event.offsetX > (event.target as HTMLElement).clientWidth;
    }
}