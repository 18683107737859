import { useEffect, useRef, useState } from "react";
import { Side, SideMap } from "./Side";
import { gridSize } from "./Sizes";

export function useMousePosition(): MouseCoords {
    let coords = useRef({ x: 0, y: 0 });
    useWindowMouseMove(onMouseMove);
    return coords;

    function onMouseMove(event: MouseEvent) {
        coords.current = { x: event.pageX, y: event.pageY };
    }
}

export interface MouseCoords {
    current: {
        x: number,
        y: number
    }
}

export function useMouseMoveWithClosestSide<T extends HTMLElement>(
    onMove: (side: Side | undefined, e: MouseEvent) => void,
    allowedSides: Partial<SideMap<boolean>> = { top: true, bottom: true, left: true, right: true },
    threshold: number = gridSize
) {
    let ref = useRef<T>(null);
    useWindowMouseMove(mouseMove);
    let isMouseDown = useWindowIsMouseDown();
    let [side, setSide] = useState<Side | undefined>();

    return ref;

    function mouseMove(e: MouseEvent) {
        let currentClosestSide = side;

        if (!isMouseDown)
            currentClosestSide = updateClosestSide(e);

        onMove(currentClosestSide, e);
    }

    function updateClosestSide(e: MouseEvent) {
        let resizeEdge = getClosestSide(e);
        setSide(resizeEdge);
        return resizeEdge;
    }

    function getClosestSide(e: MouseEvent) {
        let elementRect = ref.current!.getBoundingClientRect();
        let closestSide = findClosestSide(e, elementRect);

        return closestSide && closestSide.distance < threshold
            ? closestSide.side
            : undefined;
    }

    function findClosestSide(e: MouseEvent, rect: ClientRect | DOMRect) {
        let distances = getDistances(rect, e);

        let closest = Object.entries(distances).reduce((prev, current) =>
            allowedSides[current[0] as Side]
                ? !prev
                    ? current
                    : current[1] < prev[1]
                        ? current
                        : prev
                : prev,
            undefined as undefined | [string, any]
        );

        return closest && {
            side: closest[0] as Side,
            distance: closest[1]
        }
    }

    function getDistances(sides: ClientRect | DOMRect, e: MouseEvent): SideMap<number> {
        return {
            top: Math.abs(e.clientY - sides.top),
            bottom: Math.abs(sides.bottom - e.clientY),
            left: Math.abs(e.clientX - sides.left),
            right: Math.abs(sides.right - e.clientX),
        }
    }
}

export function useWindowMouseMove(onMouseMove: (e: MouseEvent) => void) {
    useEffect(() => {
        window.addEventListener('mousemove', onMouseMove);
        return () => window.removeEventListener('mousemove', onMouseMove);
    });
}

export function useWindowIsMouseDown() {
    let [isMouseDown, setIsMouseDown] = useState(false);

    useEffect(() => {
        window.addEventListener('mousedown', mouseDown);
        window.addEventListener('mouseup', mouseUp);

        return () => {
            window.removeEventListener('mousedown', mouseDown);
            window.removeEventListener('mouseup', mouseUp);
        };
    });

    return isMouseDown;

    function mouseDown() {
        setIsMouseDown(true);
    }

    function mouseUp() {
        setIsMouseDown(false);
    }
}
