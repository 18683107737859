import { style } from "stylemap";
import { defaultFont, disabledBackground, duration, roundedBorders, standardBorder, standardBorderWidth } from "./CommonStyles";
import { Elevation, shadow } from "./shadow";
import { standardFormControlHeight, standardSpacing, subGrid } from "./Sizes";
import { transition } from "./styling";

export const buttonHeight = standardFormControlHeight;

export let buttonWrapper = style('button-wrapper', {
    // Add margin to Clickable transition, for switching between contained and flat for loading,
    // and boxShadow for switching between contained and loading
    transition: [
        transition('background', duration.small),
        transition('margin', duration.small),
        transition('box-shadow', duration.medium)
    ]
});

export let outlinedButton = style('button-outlined', {});
export let containedButton = style('button-contained', {
    ...shadow(Elevation.RaisedButton),
    ':active': shadow(Elevation.PressedRaisedButton)
});
export let flatButton = style('button-flat', {});

export let buttonStyle = style('button-inner', {
    ...defaultFont,
    fontWeight: 'bold',
    height: buttonHeight,
    flexGrow: 1,
    border: 'none',
    ...roundedBorders,
    boxSizing: 'border-box',
    textDecoration: 'none',
    textTransform: 'uppercase',
    textAlign: 'center',
    cursor: 'inherit',
    background: 'transparent',
    transition: transition(['background', 'padding'], duration.small),
    color: 'currentColor',
    ':focus': {
        outline: 'none'
    },
    $: {
        [`.${outlinedButton} &`]: {
            padding: [0, standardSpacing - standardBorderWidth],
            border: standardBorder()
        },
        [`.${containedButton} &`]: {
            padding: [0, standardSpacing - standardBorderWidth],
            border: standardBorder('transparent')
        },
        [`.${flatButton} &`]: {
            padding: [0, standardSpacing / 2 - standardBorderWidth],
            border: standardBorder('transparent')
        }
    }
});

export let loadingButton = style('button-loading', {
    background: disabledBackground
});

export let disabledButton = style('button-disabled', {
    boxShadow: 'none'
});

export let buttonIcon = style('button-icon', {
    margin: [0, 0, 0, -subGrid],
    opacity: 1
});