import { Uuid } from "../Client/Uuid.ts";
import "../Scripts/StringFormat";
import { namespace } from "../Client/Namespace.ts";
import { extendPrototype } from "@zap/utils/lib/ExtensionHelpers";

let Zap = namespace('Zap');

extendPrototype(Function, {
    prependThisArg: function () {
        var func = this;
        return function () {
            var args = [this].concat(makeArray(arguments));
            return func.apply(this, args);
        };
    },

    appendArgs: function () {
        var args = makeArray(arguments);
        var func = this;
        return function () {
            return func.apply(this, makeArray(arguments).concat(args));
        };
    },

    using: function () {
        var args = makeArray(arguments);
        var func = this;
        return function () {
            return func.apply(this, args.concat(makeArray(arguments)));
        };
    },

    invokePoll: function (condition, instance, args) {
        if (condition()) {
            this.apply(instance, args);
        } else {
            var method = this;
            setTimeout(function () { method.invokePoll(condition, instance, args); }, 200);
        }
    },

    asPromise: function () {
        var func = this;

        return function () {
            var deferred = $.Deferred();

            var webServiceArgs = [];
            for (var i = 0; i < arguments.length; i++) {
                if ($.isFunction(arguments[i]))
                    break;

                webServiceArgs.push(arguments[i]);
            }

            webServiceArgs.push(function (response) { deferred.resolve(response); });
            webServiceArgs.push(function (response) { deferred.reject(response); });

            func.apply(func, webServiceArgs);

            return deferred.promise();
        };
    },

    throttle: function (timeout) {
        var fn = this;
        return function () {
            var thisArg = this;
            var args = makeArray(arguments);
            clearTimeout(fn._throttleTimeout);
            fn._throttleTimeout = setTimeout(function () {
                fn.apply(thisArg, args);
            }, timeout);
        }
    },

    afterTimeout: function (timeout) {
        var fn = this;
        return function () {
            var thisArg = this;
            var args = makeArray(arguments);
            setTimeout(function () {
                fn.apply(thisArg, args);
            }, timeout);
        }
    }
});

Function.identity = function (value) { return value; };

//#endregion Functions

//#region Strings

extendPrototype(String, {
    template: function (data) {
        return this.replace(/\{([^}]+)}/g, function (match, key) {
            return key in data
                ? (data[key] + '').template(data)
                : match;
        });
    },

    templateNonRecursive: function (data) {
        return this.replace(/\{([^}]+)}/g, function (match, key) {
            return key in data
                ? (data[key] + '')
                : match;
        });
    }
});

String.escapeForHtml = function (string) {
    return $('<div>').text(string || '').html();
};

String.escapeForAttribute = function (string) {
    return String.escapeForHtml(string)
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#39;');
};

String.escapeForRegEx = function (string) {
    return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
};

String.formatInCulture = function (string, culture) {
    var oldLanguage = window.msf.LC;
    window.msf.LC = culture.formatSettings;

    var args = [string].concat(makeArray(arguments).slice(2));
    var formatted = String.format.apply(String, args);

    window.msf.LC = oldLanguage;

    return formatted;
};

//#endregion Strings

// #region Numbers & Math

extendPrototype(Number, {
    roundDown: function (decimalPlaces) {
        if (decimalPlaces < 0) throw new Error("decimalPlaces must be >= 0");

        var multiplier = Math.pow(10, decimalPlaces);
        return Math.floor(this * multiplier) / multiplier;
    },

    isInteger: function () {
        return this % 1 == 0;
    }
});

Math.log10 = function () {
    var powersOfTenLookup = {};
    var limit = 307; // largest power of 10 in a double-precision float

    for (var power = 1; power <= limit; power++) {
        powersOfTenLookup[Math.pow(10, power)] = power;
    }

    return function (x) {
        return powersOfTenLookup[x] || Math.log(x) / Math.LN10;
    };
}();

Math.TAU = 2 * Math.PI;

Math.clamp = function (value, min, max) {
    return Math.min(Math.max(value, min), max);
};

Math.sign = function (value) {
    return value == 0 ? 1 : value / Math.abs(value);
}

//#endregion Numbers & Math

Zap.isTrue = function (value) {
    return !!value;
};

Zap.isNull = function (value) {
    return value === null;
};

Zap.notNull = function (value) {
    return value !== null;
};

Zap.notNullOrUndefined = function (value) {
    return value != null;
};

Zap.everyNthElement = function (array, n) {
    var filterFunc = n > 1
        ? function (tick, index) { return index % n === 0; }
        : Zap.returnTrue;

    return array.filter(filterFunc);
};

Zap.cancelable = function (element, eventName, onSuccess, onCancel) {
    var nameSpace = Uuid.create();
    var deferred = $.Deferred()
        .done(onSuccess)
        .fail(onCancel)
        .always(function () {
            setTimeout(function () {
                $(element).off(eventName + "." + nameSpace);
            }, 0);
        });

    setTimeout(function () {
        $(element).on(eventName + "." + nameSpace, function () {
            deferred.reject();
        });
    }, 0);

    return deferred;
};

// Hack until we can use native Promises effectively
Zap.promise = function (promise) {
    return {
        then: function () {
            var pipePromise = (promise.pipe || promise.then).apply(promise, arguments);
            return Zap.promise(pipePromise);
        },

        catch: function (errorCallback) {
            return this.then(null, errorCallback);
        },

        finally: function (callback) {
            var resultPromise = (promise.always || promise.finally).apply(promise, [callback]);
            return Zap.promise(resultPromise);
        },

        promise: function () { // Needed to make $.when() recognise this object as a promise
            return this;
        }
    };
}

Zap.promise.onSettled = function (promise) {
    return Zap.promise(promise).then(
        function () { },
        function () { return $.Deferred().resolve(); } // jQuery's pipe method turns values into rejection reasons instead of resolved values
    );
};

function makeArray(args) {
    return Array.prototype.slice.call(args);
}
