import { combineRefs } from "@zap/utils/lib/ReactHelpers";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { Column } from "./Box";
import { disabledTextColor, errorColor, highlightColor, successColor } from "./CommonStyles";
import { FocusHelper, IFocusHelper, IFocusProps } from "./FocusHelper";
import { FormLabel } from "./FormLabel";
import { HelperText, IHelperTextProps } from "./HelperText";
import { IUnderlineProps, Underline } from "./Underline";

export interface IFormControlProps extends IUnderlineProps, IHelperTextProps, Partial<IFocusProps> {
    label?: React.ReactNode;
    hideUnderline?: boolean;
    children(helper: IFocusHelper & { inputRef: React.Ref<HTMLInputElement> }): React.ReactNode;
}

export function FormControl(props: IFormControlProps) {
    let inputRef = useRef<HTMLInputElement>(null);
    let [inputValid, setInputValid] = useState(undefined as undefined | boolean);
    useEffect(updateInputValid);

    let { label, hideUnderline, children,
        isFocused, isValid, isLoading, disabled,
        ...helperTextProps } = props;

    if (inputValid != null)
        isValid = inputValid && isValid;

    return <>
        <FocusHelper disabled={props.disabled} onFocus={props.onFocus} onBlur={props.onBlur}>
            {(focus, focusRef) => <Column positioned noSpacing onInput={updateInputValid}>
                <FormLabel
                    label={label}
                    color={labelColor(disabled, isValid, isFocused || focus.isFocused)} />
                {children({ ...focus, isFocused: focus.isFocused || !!isFocused, inputRef: combineRefs(inputRef, focusRef) })}
                {!hideUnderline &&
                    <Underline isFocused={isFocused || focus.isFocused} isValid={isValid} isLoading={isLoading} disabled={disabled} data-testid={testIds.underline} />}
            </Column>}
        </FocusHelper>
        <HelperText disabled={disabled} {...helperTextProps} />
    </>;

    function updateInputValid() {
        if (inputRef.current)
            setInputValid(inputRef.current.checkValidity());
    }
}

function labelColor(disabled: boolean | undefined, isValid: boolean | undefined, isFocused: boolean) {
    return disabled
        ? disabledTextColor
        : validityColor(isValid) || focusedColor(isFocused);
}

function validityColor(isValid?: boolean) {
    return isValid
        ? successColor
        : isValid === false
            ? errorColor
            : undefined;
}

function focusedColor(isFocused: boolean) {
    return isFocused
        ? highlightColor
        : undefined;
}

export const testIds = {
    underline: 'underline'
}
