import { mapValues } from "@zap/utils/lib/Object";
import * as React from 'react';
import { disabledOpacity, hoverOpacity, interactiveOpacity, successColor, uninteractiveOpacity, warningColor } from '../CommonStyles';
import { classes, defaultPx, style, StyleCollection } from '../styling';
import { Icons } from './Icons';

export type IconName = keyof typeof Icons;

export type IconSize = keyof typeof IconSizes;
export const IconSizes = {
    tiny: 18,
    small: 24,
    large: 48
};

export type DefaultColor = keyof typeof DefaultColors;
export const DefaultColors = {
    check: successColor,
    warning: warningColor
}

export type IIconProps = {
    name: IconName;
    color?: string;
    size?: IconSize;
    nonStandardSize?: number;
    styles?: StyleCollection;
};

export function Icon({ name, color, size = 'small', nonStandardSize, styles }: IIconProps) {
    let iconSize = nonStandardSize ?? IconSizes[size];

    return React.createElement(Icons[name], {
        'data-testid': `icon-${name}`,
        className: classes([icon, styles]),
        fill: color ?? DefaultColors[name as DefaultColor],
        height: defaultPx(iconSize),
        width: defaultPx(iconSize)
    } as React.SVGProps<SVGSVGElement>);
}

/** Use this to select the icon element. */
export const icon = style('icon', {
    opacity: uninteractiveOpacity,
    pointerEvents: 'none'
});

/** For an icon that will be interactive. */
export const iconInteractive = style('icon-interactive', {
    pointerEvents: 'none', // IE 11 on Win 7: https://connect.microsoft.com/IE/feedback/details/796745/mouse-events-are-not-delivered-at-all-anymore-when-inside-an-svg-a-use-is-removed-from-the-dom
    opacity: interactiveOpacity
});

/** For an interactive icon for a component that is being hovered over, or is in focus. */
export const iconHover = style('icon-hover', { opacity: hoverOpacity });

/** For an interactive icon that's disabled. Marked as !important so hover effect isn't applied. */
export const iconDisabled = style('icon-disabled', { opacity: disabledOpacity });

export const iconIgnoreInteractive = style('icon-ignoreInteractive', {
    $: {
        [`&.${iconDisabled}`]: {
            pointerEvents: 'none'
        }
    }
});

export const testIds = mapValues(Icons, (_, name) => `icon-${name}`);

export function isIconName(value?: string): value is IconName {
    return !!value && Object.keys(Icons).includes(value);
}

export function asIconOrDefault(value?: string | null): IconName | undefined {
    return value
        ? isIconName(value)
            ? value
            : 'warning'
        : undefined;
}