import { useEffect, useState } from "react";
import { useMouseMoveWithClosestSide, useWindowIsMouseDown } from "./Mouse";
import { Side, SideMap } from "./Side";
import { style } from "./styling";

export function useWindowResize(onResize: (e: UIEvent) => void) {
    useEffect(() => {
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    });
}

export function useResizable<T extends HTMLElement>(onResize?: (width: number, height: number) => void, allowedSides?: Partial<SideMap<boolean>>) {
    let isMouseDown = useWindowIsMouseDown();
    let [size, setSize] = useState<{ width?: number, height?: number }>();
    let ref = useMouseMoveWithClosestSide<T>(mouseMove, allowedSides);

    return [ref, size] as const;

    function mouseMove(side: Side | undefined, e: MouseEvent) {
        updateCursor(side);

        if (side && isMouseDown) {
            resize(side, e);
            document.body.style.userSelect = 'none';
        } else {
            document.body.style.userSelect = '';
        }
    }

    function updateCursor(side: Side | undefined) {
        document.body.classList.remove(ewResize.toString());
        document.body.classList.remove(nsResize.toString());

        if (side) {
            let cursorClass = side === 'top' || side === 'bottom'
                ? nsResize
                : ewResize;
            document.body.classList.add(cursorClass.toString());
        }
    }

    function resize(side: Side, e: MouseEvent) {
        let rect = ref.current!.getBoundingClientRect();
        let height = rect.height;
        let width = rect.width;

        if (side === 'top')
            height += rect.top - e.clientY;
        else if (side === 'bottom')
            height += e.clientY - rect.bottom;
        else if (side === 'left')
            width += rect.left - e.clientX;
        else if (side === 'right')
            width += e.clientX - rect.right;

        setSize({ width, height });
        onResize?.(width, height);
    }
}

let nsResize = style('body-nsresize', {
    $: {
        '*': {
            cursor: 'ns-resize'
        }
    }
});

let ewResize = style('body-ewresize', {
    $: {
        '*': {
            cursor: 'ew-resize'
        }
    }
});
