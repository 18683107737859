import * as React from 'react';
import { useEffect, useRef, useState } from "react";
import { findDOMNode } from "react-dom";
import { filterObject, valueOf } from "./Object";

export function cloneAndGetRef(element: React.ReactElement, getRef: (ref: Element) => void, props: object = {}) {
    return cloneReactElement(element, {
        ...props, ref: (value: React.ReactInstance) => {
            let ref = value && findDOMNode(value);
            if (isElement(ref))
                getRef(ref);
        }
    });
}

export function cloneReactElement(element: React.ReactElement, props: object) {
    if ((element as any).ref && (props as any).ref)
        throw new Error(`Ref conflict on ${typeof element.type == 'string' ? element.type : (element.type as any).displayName}. Move the inner ref up to the component doing the cloning.`);

    return React.cloneElement(element, props);
}

function isElement(element: Element | Text | null): element is Element {
    return !!element && !!(element as Element).getBoundingClientRect;
}

export function stopPropagation<T>(eventHandler?: React.MouseEventHandler<T>): React.MouseEventHandler<T> {
    return (e: React.MouseEvent<any>) => {
        e.stopPropagation();
        eventHandler?.(e);
    };
}

export function dataProps<T extends object>(props: T): object {
    return filterObject(props, startsWithData);
}

export function nonDataProps<T extends object>(props: T): object {
    return filterObject(props, key => !startsWithData(key));
}

export function validProps<T extends object>(props: T): object {
    return filterObject(props, key =>
        miscProps.includes(key as string) || startsWithData(key)
    );
}

function startsWithData(key: string | symbol | number) {
    return (key as string).startsWith('data-');
}

const miscProps = [
    'rich-tooltip' // used in BI for tooltips
];

export interface IChildren {
    children: React.ReactNode;
}

export type RefFn<T> = { bivarianceHack(instance: T | null): void }["bivarianceHack"];

export function combineRefs<T>(...refs: (React.Ref<T> | undefined)[]): RefFn<T> {
    return (value: T | null) => refs.forEach(r => setRef(r, value));
}

export function setRef<T>(ref: React.Ref<T> | undefined, value: T) {
    if (isRefObject(ref))
        (ref as React.MutableRefObject<T>).current = value;
    else if (ref)
        ref(value);
}

function isRefObject<T>(ref: React.Ref<T> | undefined): ref is React.RefObject<T> {
    return !!ref && typeof ref == 'object';
}

export interface RefForwardedComponent<P, R> extends React.ForwardRefExoticComponent<React.PropsWithoutRef<P> & React.RefAttributes<R>> {
    /** Use `displayName` instead */
    name: unknown;
    displayName: string;
}

export interface IDisplayName {
    displayName: string;
}

export function forwardRef<R, P = {}>(component: React.RefForwardingComponent<R, P>): RefForwardedComponent<P, R> {
    let forwarded = React.forwardRef(component) as RefForwardedComponent<P, R>;
    if (process.env.NODE_ENV != 'production' && namedFunctionsSupported && !component.name)
        throw new Error("Must use a named function");
    forwarded.displayName = component.name;
    return forwarded;
}

export function useLazyRef<T>(getValue: () => T) {
    let ref = useRef(undefined! as T);
    if (ref.current === undefined)
        ref.current = getValue();
    return ref;
}

export function useControlledState<T>(value: T) {
    return useSemiControlledState<T>(undefined, value);
}

export function useSemiControlledState<T>(defaultValue: T | undefined, value: T | undefined, onValueChanged: (value: T) => void = () => { }) {
    let [currentValue, setCurrentValue] = useState<T>(defaultValue === undefined ? value! : defaultValue);

    useEffect(() => {
        if (value !== undefined) {
            onValueChanged(value);
            setCurrentValue(value);
        }
    }, [valueOf(value)]);

    return [currentValue, setCurrentValue] as const;
}

export function useMaybeControlledState<T>(defaultValue: T | undefined, value: T | undefined): [T, React.Dispatch<React.SetStateAction<T>>] {
    if (value !== undefined)
        return [value, () => { }];

    if (defaultValue !== undefined)
        return useState<T>(defaultValue!);

    throw new Error("Must specify either defaultValue or value");
}

export function useEventListener<TEvent extends keyof HTMLElementEventMap, TElement extends HTMLElement>(
    type: TEvent,
    listener: (event: HTMLElementEventMap[TEvent], element: TElement) => any,
    options?: boolean | AddEventListenerOptions,
    ref: React.RefObject<TElement> = useRef<TElement>(null) as React.MutableRefObject<TElement>,
) {
    useEffect(() => {
        const element = ref.current;
        if (element) {
            element.addEventListener(type, handleEvent, options);
            return () => element.removeEventListener(type, handleEvent, options);
        }
    });

    return ref;

    function handleEvent(e: HTMLElementEventMap[TEvent]) {
        listener(e, ref.current!);
    }
}

var namedFunctionsSupported = !!(function test() { }).name; // For IE
