import "./ArrayExtensions";
import { ArrayPredicate } from "./ArrayExtensions";

export function move<T, TThis>(array: T[], predicate: ArrayPredicate<T, TThis>, delta: number, thisArg?: TThis) {
    return moveTo(array, predicate, predicate, delta, thisArg);
}

export function moveBefore<T, TThis>(
    array: T[],
    itemToMovePredicate: ArrayPredicate<T, TThis>,
    itemToMoveNextToPredicate: ArrayPredicate<T, TThis>,
    thisArg?: TThis
) {
    return moveTo(array, itemToMovePredicate, itemToMoveNextToPredicate, 0, thisArg);
}

export function moveAfter<T, TThis>(
    array: T[],
    itemToMovePredicate: ArrayPredicate<T, TThis>,
    itemToMoveNextToPredicate: ArrayPredicate<T, TThis>,
    thisArg?: TThis
) {
    return moveTo(array, itemToMovePredicate, itemToMoveNextToPredicate, 1, thisArg);
}

export function moveTo<T, TThis>(
    array: T[],
    itemToMovePredicate: ArrayPredicate<T, TThis>,
    itemToMoveNextToPredicate: ArrayPredicate<T, TThis>,
    deltaFromTarget: number,
    thisArg?: TThis
) {
    let items = array.clone();

    let itemToMove = items.findOrThrow(itemToMovePredicate, thisArg);
    let itemToMoveNextTo = items.findOrThrow(itemToMoveNextToPredicate);

    let originalIndex = items.indexOf(itemToMove);
    let targetItemIndex = items.indexOf(itemToMoveNextTo);

    let targetIndex = targetItemIndex + deltaFromTarget;
    if (originalIndex < targetItemIndex)
        targetIndex--;  // Target will shift left when item is removed

    items.splice(originalIndex, 1);
    items.splice(targetIndex, 0, itemToMove);

    return items;
}

export function range(start: number, count?: number): number[] {
    if (count == null) {
        count = start;
        start = 0;
    }

    let array = [];
    for (let i = 0; i < count; i++)
        array[i] = i + start;
    return array;
}

export function repeat<T>(item: T, count: number): T[] {
    let array = [];
    for (let i = 0; i < count; i++)
        array.push(item);
    return array;
}

export function iterate<T>(firstItem: T, getNextItem: (item: T) => T | null | undefined): T[] {
    let array = [] as T[];
    let item = firstItem as T | null | undefined;
    do {
        array.push(item!);
        item = getNextItem(item!);
    } while (item != null);

    return array;
}

export function treePreOrder<T>(nodes: T[], getChildren: (node: T) => (T[] | undefined)): T[] {
    return nodes.flatMap(n => [n, ...treePreOrder(getChildren(n) || [], getChildren)]);
}

export function arraysEqual(arrays: any[][]) {
    return arrays.length
        ? zip(arrays).every(items => items.every(i => i == items[0]))
        : true;
}

export function zip<T1>(arrays: [T1[]]): [T1][];
export function zip<T1, T2>(arrays: [T1[], T2[]]): [T1 | undefined, T2 | undefined][];
export function zip<T1, T2, T3>(arrays: [T1[], T2[], T3[]]): [T1 | undefined, T2 | undefined, T3 | undefined][];
export function zip<T1, T2, T3, T4>(arrays: [T1[], T2[], T3[], T4[]]): [T1 | undefined, T2 | undefined, T3 | undefined, T4 | undefined][];
export function zip<T1, T2, T3, T4, T5>(arrays: [T1[], T2[], T3[], T4[], T5[]]): [T1 | undefined, T2 | undefined, T3 | undefined, T4 | undefined, T5 | undefined][];
export function zip(arrays: any[][]): (any | undefined)[][];
export function zip<TResult, T1, TThis = void>(arrays: [T1[]], selector: (this: TThis, item1: T1) => TResult, thisArg?: TThis): TResult[];
export function zip<TResult, T1, T2, TThis = void>(arrays: [T1[], T2[]], selector: (this: TThis, item1: T1, item2: T2) => TResult, thisArg?: TThis): TResult[];
export function zip<TResult, T1, T2, T3, TThis = void>(arrays: [T1[], T2[], T3[]], selector: (this: TThis, item1: T1, item2: T2, item3: T3) => TResult, thisArg?: TThis): TResult[];
export function zip<TResult, T1, T2, T3, T4, TThis = void>(arrays: [T1[], T2[], T3[], T4[]], selector: (this: TThis, item1: T1, item2: T2, item3: T3, item4: T4) => TResult, thisArg?: TThis): TResult[];
export function zip<TResult, T1, T2, T3, T4, T5, TThis = void>(arrays: [T1[], T2[], T3[], T4[], T5[]], selector: (this: TThis, item1: T1, item2: T2, item3: T3, item4: T4, item5: T5) => TResult, thisArg?: TThis): TResult[];
export function zip<TResult, TThis = void>(arrays: any[][], selector: (this: TThis, ...items: any[]) => TResult, thisArg?: TThis): TResult[];
export function zip<TThis = void>(arrays: any[][], selector?: (this: TThis, ...items: any[]) => any, thisArg?: TThis) {
    selector = selector || ((...items) => items);
    let maxLength = arrays.map(a => a.length).max();

    return range(maxLength)
        .map(i => selector!.apply(thisArg as TThis, arrays.map(array => array[i])));
}