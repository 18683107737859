import { style, Styled } from "@zap/ui/lib/styling";
import { ITooltipForChildProps, ITooltipForExternalElementProps, Tooltip as BaseTooltip } from "@zap/ui/lib/Tooltip";
import * as React from "react";
import { ITooltipDefinition } from "./Desktop/TooltipService";
import { forwardRef } from "@zap/utils/lib/ReactHelpers";

export type ITooltipProps = {
    definition?: ITooltipDefinition;
    dangerousHtml?: string;
} & (ITooltipForExternalElementProps | ITooltipForChildProps);

export const Tooltip = forwardRef(function Tooltip(props: ITooltipProps, ref: React.Ref<any>) {
    let { definition, dangerousHtml, ...rest } = props;

    if (definition) {
        rest.title = definition.title;
        rest.content = dangerousHtmlTooltip(definition.text!);
        rest.helpUrl = definition.helpUrl;
    } else if (dangerousHtml) {
        rest.content = dangerousHtmlTooltip(dangerousHtml)
    }

    return <BaseTooltip helpText={WebCoreText.Label_MoreHelp} ref={ref} {...rest} />;
});

export function dangerousHtmlTooltip(dangerousHtml: string): React.ReactNode {
    return <Styled.div styles={htmlTooltip} dangerouslySetInnerHTML={{ __html: dangerousHtml }} />;
}

let htmlTooltip = style('htmlTooltip', {
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word'
});