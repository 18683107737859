import { border, style, animation } from "./styling";

export let highlightColor = '#00a996';
export let darkHighlightColor = '#007968';
export let lightHighlightColor = '#a8f0e5';
export let hoverHighlightColor = '#ebfffc';
export let selectedHighlightColor = lightHighlightColor;
export let errorColor = '#e45847';
export let successColor = '#55ab55';
export let warningColor = '#ffa500';
export let blackColor = '#0c0c0c';
export let greyColor = '#5c5c5c';
export let whiteColor = '#fcfcfc';
export let darkGreyColor = '#333333';
export let lightGreyColor = '#e6e6e6';
export let lightGreyBorderColor = '#d9d9d9';
export let lightGreyFocusColor = '#d4d4d4';
export let blueColor = '#35bbe8';
export let linkColor = '#39d3b7';

export let formControlBackground = '#f5f5f5';
export let formControlHover = '#ececec';
export let formControlFocus = '#ececec';

export let duration = {
    /** Small movements, like a switch */
    small: 100,
    /** Things appearing out of nowhere, like a menu */
    appear: 120,
    /** Larger movements */
    medium: 250,
    /** Sliding in from the edge */
    slideIn: 250,
    /** Sliding out to the edge */
    slideOut: 200,
};

export let standardBorderColor = lightGreyColor;
export let standardBorderWidth = 1;
export let standardBorderStyle = 'solid';
export let standardBorder = (color = standardBorderColor) => border(standardBorderWidth, standardBorderStyle, color);
export let roundedBorderRadius = 2;
export let roundedBorders = style('roundedBorders', { borderRadius: roundedBorderRadius });

/** For foreground items on a disabled background */
export let disabledForeground = '#808080';
export let disabledBackground = '#ebebe4';
/** For text on a normal background */
export let disabledTextColor = '#b3b3b3';

export let uninteractiveOpacity = 0.8;
export let interactiveOpacity = 0.56;
export let hoverOpacity = 0.7;
export let disabledOpacity = 0.26;
export let hoverBackgroundOpacity = 0.06;
export let focusBackgroundOpacity = 0.12;
export let activeBackgroundOpacity = 0.2;

export let fontSizes = {
    small: 10,
    label: 12,
    default: 14,
    headingSmall: 16,
    headingLarge: 18
}

export let defaultFontColor = style('defaultFontColor', { color: blackColor });
export let lightFontColor = style('lightFontColor', { color: greyColor });

export let labelFontSize = style('labelFontSize', { fontSize: fontSizes.label });
export let defaultFontSize = style('defaultFontSize', { fontSize: fontSizes.default });
export let smallHeadingFontSize = style('smallHeadingFontSize', { fontSize: fontSizes.headingSmall });
export let largeHeadingFontSize = style('largeHeadingFontSize', { fontSize: fontSizes.headingLarge });

export let lineHeight = 1.2;

export let defaultFontFamily = style('defaultFontFamily', { fontFamily: '"Open Sans", "Segoe UI", Helvetica, Tahoma, sans-serif' });

export let defaultFont = style('defaultFont', { ...defaultFontFamily, ...defaultFontSize });
export let labelFont = style('labelFont', { ...defaultFontFamily, ...labelFontSize });

export let inlineBlock = style('inlineBlock', { display: 'inline-block' });

export let pageContentWidth = '1200px';

export let truncateText = style('truncateText', {
    flexShrink: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
});

export let hideIfEmpty = style('hideIfEmpty', {
    ':empty': { display: 'none' }
});

export let zIndexes = {
    belowPage: -1,
    page: 0,
    abovePage: 1,
    popup: 2,
    loadingOverlay: 3,
    notification: 4
}

export let fadeInAnimation = style('fadeIn', {
    animation: {
        keyframes: {
            from: { opacity: 0 },
            to: { opacity: 1 }
        },
        animationDuration: duration.small
    }
});

export let fadeOutAnimation = style('fadeOut', {
    animation: animation({
        from: { opacity: 1 },
        to: { opacity: 0 }
    }, duration.small, 'ease')
});

export let flexGrow = style('flexGrow', {
    flexGrow: 1
});

export let flexShrink = style('flexShrink', {
    flexShrink: 1
})