import { functor } from "@zap/utils/lib/Function";
import { valueOf } from "@zap/utils/lib/Object";
import { combineRefs, dataProps, forwardRef, IChildren, nonDataProps, useMaybeControlledState, useSemiControlledState } from "@zap/utils/lib/ReactHelpers";
import * as React from "react";
import { useRef, useState } from "react";
import { Key } from "ts-key-enum";
import { halfBleed, Row } from "./Box";
import { defaultFontFamily, defaultFontSize, disabledBackground, disabledForeground, flexGrow, flexShrink, formControlBackground, formControlFocus, formControlHover, roundedBorders } from "./CommonStyles";
import { fieldAsRef, IFormFieldProps, useFormLabelSpacing, useFormOnKeyDown } from "./Form";
import { FormControl } from "./FormControl";
import { IFieldHelperTextProps } from "./HelperText";
import { halfSpacing, standardFormControlHeight } from "./Sizes";
import { defaultPx, style, StyleCollection, Styled } from "./styling";
import { IFieldUnderlineProps } from "./Underline";

export interface IInputProps<TValue>
    extends
    Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'value' | 'defaultValue' | 'onFocus' | 'onBlur' | 'ref'>,
    IFieldUnderlineProps<TValue>,
    IFieldHelperTextProps<TValue>,
    IFormFieldProps<HTMLInputElement> {

    value?: TValue;
    defaultValue?: TValue;
    /** Typed, only called on enter and blur */
    onValueChange?(value: TValue): void;
    onFocus?: React.FocusEventHandler<HTMLElement>;
    onBlur?: React.FocusEventHandler<HTMLElement>;
    label?: string;
    dirtyValidation?: boolean;
    styles?: StyleCollection;
    grow?: boolean;
    shrink?: boolean;
    templateText?: string;
    transparent?: boolean;
    hideUnderline?: boolean;
}

export type TextBoxType = 'tel' | 'url' | 'text' | 'email' | 'password';
export interface ITextBoxProps extends IInputProps<string> {
    type?: TextBoxType;
}

export const TextBox = forwardRef(function TextBox(props: ITextBoxProps, ref: React.Ref<HTMLInputElement>) {
    let { type = 'text', ...inputProps } = props;
    return useInput({ type, props: inputProps }).render({}, ref);
});

export interface IInputConfig<TValue> {
    type?: string;
    getValue?(input: HTMLInputElement): TValue | undefined;
    props: IInputProps<TValue>;
    getText?(value: TValue): string;
    tag?: 'input' | 'textarea';
}

export function useInput<TValue>(inputConfig: IInputConfig<TValue>) {
    let {
        type = 'text',
        getValue = (input: HTMLInputElement) => input.value as any as TValue,
        props,
        getText = String,
        tag = 'input' as const
    } = inputConfig;

    let inputRef = useRef<HTMLInputElement>();

    let fieldRef = fieldAsRef(props.field);
    let formKeyDown = useFormOnKeyDown();

    let { label, value, defaultValue, width, styles, grow, shrink, className, dirtyValidation, onFocus, onBlur, onChange, onValueChange, templateText, placeholder, isFocused, showEmptyHelper,
        transparent, hideUnderline,
        isValid,
        isLoading = false,
        helperText,
        warningText,
        errorText,
        ...inputProps } = props;
    inputProps = nonDataProps(inputProps);

    let [isDirty, setDirty] = useState(false);
    let [currentValue, setCurrentValue] = useMaybeControlledState(defaultValue, value);
    let [currentText, setCurrentText] = useSemiControlledState(undefined, getText(currentValue));

    let ignoreValidation = dirtyValidation && !isDirty;

    let validity = inputProps.disabled || ignoreValidation
        ? undefined
        : functor(isValid)(currentValue);

    let hasEmptyValue = currentValue === undefined
        || typeof currentValue == 'string' && !currentValue;

    let isEmpty = hasEmptyValue
        && !props.defaultValue
        && !typeHasDefaultPlaceholder();

    let formSpacing = useFormLabelSpacing(label, true);

    return {
        disabled: inputProps.disabled,
        render(extraProps: Partial<IChildren>, ref: React.Ref<HTMLInputElement>) {
            return <Styled.label styles={[textbox, halfBleed, grow && flexGrow, shrink && flexShrink, formSpacing, styles]} className={className} inline={{ width }} role="textbox" {...dataProps(props)}>
                <FormControl
                    label={label}
                    isValid={validity}
                    isFocused={isFocused}
                    isLoading={functor(isLoading)(currentValue)}
                    disabled={inputProps.disabled}
                    hideUnderline={hideUnderline}
                    showEmptyHelper={showEmptyHelper}
                    helperText={functor(helperText)(currentValue)}
                    warningText={ignoreValidation ? '' : functor(warningText)(currentValue)}
                    errorText={ignoreValidation ? '' : functor(errorText)(currentValue)}
                    onFocus={onFocus}
                    onBlur={blur}>
                    {({ getFocusProps, isFocused, inputRef: formControlInputRef }) =>
                        <Row positioned halfSpacing
                            minHeight={standardFormControlHeight}
                            styles={[transparent ? transparentStyle : lightStyle, isFocused && focusedStyle, inputProps.disabled && disabledStyle]}
                            {...getFocusProps()}>
                            <Styled
                                tag={tag}
                                type={type}
                                ref={combineRefs(inputRef, formControlInputRef, ref, fieldRef)}
                                value={currentText}
                                styles={input}
                                placeholder={!label || isFocused ? placeholder : undefined}
                                {...inputProps}
                                onChange={changed}
                                onKeyDown={keydown}
                                data-validity={validity}
                                role="" />{/* prevent implicit role on the input, there's an explicit role on the label */}
                            {!!templateText && (isFocused || !isEmpty || !label)
                                && <Styled.span styles={templateTextStyle}>{templateText}</Styled.span>}
                            {extraProps.children}
                        </Row>}
                </FormControl>
            </Styled.label>
        }
    };

    function changed(event: React.ChangeEvent<HTMLInputElement>) {
        setCurrentText(event.target.value);
        dirtyEventOverride(event, onChange);
    }

    function keydown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key == Key.Enter)
            updateValue(event.currentTarget);
        dirtyEventOverride(event, formKeyDown);
    }

    function blur(event: React.FocusEvent<HTMLInputElement>) {
        updateValue(inputRef.current!);
        dirtyEventOverride(event, onBlur);
    }

    function updateValue(input: HTMLInputElement) {
        let value = getValue(input);
        if (value === undefined) {
            setCurrentText(getText(currentValue));
        } else {
            setCurrentValue(value);
            if (valueOf(value) !== valueOf(currentValue))
                onValueChange?.(value);
            setCurrentText(getText(value));
        }
    }

    function dirtyEventOverride<T extends React.SyntheticEvent<HTMLInputElement>>(event: T, originalHandler?: (event: T) => void) {
        setDirty(true);
        originalHandler?.(event);
    }

    function typeHasDefaultPlaceholder() {
        switch (props.type) {
            case 'date':
            case 'datetime-local':
            case 'month':
            case 'time':
            case 'week':
                return true;
            default:
                return false;
        }
    }
}

let textbox = style('textbox', {
    display: 'inline-block',
    position: 'relative',
    cursor: 'text'
});

let focusedStyle = style('is-textbox-focused', {});

let lightStyle = style('textbox-light', {
    ...roundedBorders,
    background: formControlBackground,
    ':hover': {
        background: formControlHover,
    },
    $: {
        [`&.${focusedStyle}`]: {
            background: formControlFocus
        }
    }
});

let transparentStyle = style('textbox-transparent', {
    background: 'transparent'
});

let disabledStyle = style('is-textbox-disabled', {
    color: disabledForeground,
    background: disabledBackground,
    ':hover': { background: disabledBackground }
});

export const textBoxLineHeight = 20;
export const textBoxVerticalPadding = (standardFormControlHeight - textBoxLineHeight) / 2;

let input = style('textbox-input', {
    ...defaultFontSize,
    ...defaultFontFamily,
    border: 'none',
    outline: 'none',
    flex: 1,
    width: 0,
    minHeight: standardFormControlHeight,
    padding: [textBoxVerticalPadding, halfSpacing],
    lineHeight: defaultPx(textBoxLineHeight),
    overflow: 'hidden',
    background: 'transparent',
    $: {
        '::-webkit-search-cancel-button': {
            display: 'none'
        }
    }
});

let templateTextStyle = style('textbox-template', {
    color: disabledForeground,
    height: standardFormControlHeight,
    lineHeight: `${standardFormControlHeight}px`,
    paddingRight: halfSpacing
});
