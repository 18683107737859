import { MaybeFunction } from "@zap/utils/lib/Function";
import { Flip } from "flippo-react";
import * as React from "react";
import { noSpacing } from "./Box";
import { disabledTextColor, errorColor, fontSizes, labelFont, lightFontColor, warningColor } from "./CommonStyles";
import { FlipScope } from "./Flip";
import { standardLabelStyle } from "./StandardLabel";
import { style, Styled } from "./styling";
import { Tooltip } from "./Tooltip";

export interface IFieldHelperTextProps<T> {
    /** Not supported for multi-line helpers. */
    showEmptyHelper?: boolean;
    helperText?: MaybeFunction<[T], string | undefined>;
    warningText?: MaybeFunction<[T], string | undefined>;
    errorText?: MaybeFunction<[T], string | undefined>;
}

export interface IHelperTextProps {
    /** Not supported for multi-line helpers. */
    showEmptyHelper?: boolean;
    helperText?: string;
    warningText?: string;
    errorText?: string;
    disabled?: boolean;
}

export function HelperText(props: IHelperTextProps) {
    let text = props.errorText
        ?? props.warningText
        ?? props.helperText;

    let show = !!text || props.showEmptyHelper;

    let color = props.disabled ? disabledText
        : props.errorText ? errorText
            : props.warningText ? warningText
                : defaultText;

    return <FlipScope triggerData={{ show, hasText: !!text }}>
        <Flip id="height" shouldFlip={(prev, next) => prev.show != next.show} animateProps={['height', 'min-height' as any]}>
            <Tooltip content={text} >
                <Styled.div styles={[color, labelFont, helperTextStyle, color, show ? showHelper : hideHelper, !show && noSpacing]}>
                    {!!text && <Flip id="text" shouldFlip={(prev, next) => prev.hasText != next.hasText}>
                        <Styled.div styles={[labelFont, color]}>{text}</Styled.div>
                    </Flip>}
                </Styled.div>
            </Tooltip>
        </Flip>
    </FlipScope>;
}

let errorText = style('helper-error', { color: errorColor });
let warningText = style('helper-warning', { color: warningColor });
let defaultText = style('helper-default', { ...lightFontColor });
let disabledText = style('helper-disabled', { color: disabledTextColor });

let showHelper = style('is-helper-visible', {
    ...standardLabelStyle(fontSizes.label, 'unset'),
    minHeight: 17 // height of a single line of text
});

let hideHelper = style('is-helper-hidden', {
    height: 0
});

let helperTextStyle = style('helper-text', {
    userSelect: 'none'
});