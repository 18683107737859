import { useRef, useEffect } from "react";

export function useThrottle<T extends (...args: any) => void>(fn: T, timeoutMs: number) {
    let [setTimeout, clearTimeout] = useTimeout();

    return (...args: Parameters<T>) => {
        clearTimeout();
        setTimeout(() => fn(...args), timeoutMs);
    }
}

export function useTimeout() {
    let timeout = useRef(null as any);

    useEffect(() => () => clearTimeout(timeout.current), []);

    return [
        (fn: () => void, delayMs: number) => {
            clearTimeout(timeout.current);
            if (delayMs)
                timeout.current = setTimeout(fn, delayMs);
            else
                fn();
        },
        () => clearTimeout(timeout.current)
    ] as [(fn: () => void, delayMs: number) => void, () => void];
}